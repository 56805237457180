exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=default" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx?export=default" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx?export=default" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx?export=default" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx?export=default" /* webpackChunkName: "component---src-templates-post-tsx" */)
}



exports.head = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=head" /* webpackChunkName: "component---src-pages-404-tsxhead" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx?export=head" /* webpackChunkName: "component---src-pages-blog-tsxhead" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx?export=head" /* webpackChunkName: "component---src-pages-kontakt-tsxhead" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx?export=head" /* webpackChunkName: "component---src-templates-page-tsxhead" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx?export=head" /* webpackChunkName: "component---src-templates-post-tsxhead" */)
}

